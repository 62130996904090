<template>
  <div class="top">
    听我说，谢谢你!
  </div>
</template>

<script></script>

<style lang="scss">
.top {
  width: 90vw;
  margin-left: 5vw;
  height: 200px;
  background-color: rgba(252, 88, 121, 0.509);
}
</style>
